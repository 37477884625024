import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import ReactTooltip from 'react-tooltip';

import {
  fetchNextDataSet,
  getCustomOrders,
  getCustomTestCases,
  getHostingExecutions,
  postHostingAbortTransactions,
  postPublishReports,
  postPublishToJIRA,
  getSharedExecutions,
  postResubmit,
} from 'utils/api.service';
import CSVReportGenerator from 'utils/CSVReportGenerator';
import debouncePromise from 'utils/debouncePromise';
import downloadFile from 'utils/downloadFile';

import Checkbox from 'components/Checkbox';
import LoadingSpinner from 'components/LoadingSpinner';
import ConfirmationModal from 'components/ConfirmationModal';
import MetafieldsModal from 'components/MetafieldsModal';
import Channels from 'components/Channels';
import schema from './schema';
import JobsQueueModal from './JobsQueueModal';
import JobsQueueResubmitModal from './JobsQueueResubmitModal';

import {
  setDefaultFilter,
  deleteTransactions,
} from 'store/actions/jobsQueue_actions';
import { addNewNotification } from 'store/actions/notification_actions';
import jobsQueueActions from 'store/actions/jobsQueue_actions';
import { setLightboxState } from 'store/actions/lightbox_actions';
import TestCasesTable from './TestCasesTable';

import 'react-table/react-table.css';

const DEBOUNCE_DELAY = 2000;
const debouncedGetCustomOrders = debouncePromise(
  getCustomOrders,
  DEBOUNCE_DELAY
);
const debouncedGetCustomTestCases = debouncePromise(
  getCustomTestCases,
  DEBOUNCE_DELAY
);
const debouncedGetHostingExecutions = debouncePromise(
  getHostingExecutions,
  DEBOUNCE_DELAY
);
const debouncedGetCustomSharedExecutions = debouncePromise(
  getSharedExecutions,
  DEBOUNCE_DELAY
);

class JobsQueue extends React.Component {
  state = {
    records: [],
    source: '',
    nextUrl: '',
    prevUrl: '',
    refreshing: false,
    loadingRecords: true,
    aborting: false,
    abortReason: '',
    modal: {},
    modalTitle: '',
    modalMessage: '',
    selectedRecords: new Set(),
    selectedTransactions: new Set(),
    selectedEntries: {},
    searchValue: '',
    activetab: 'my-reports',
    resubmittedtransactions: [],
    filters: {
      status: 'all',
    },
    requestInProgress: false,
    expandedExecutions: new Set(),
    resizedColumns: [],
    testCasesColumns: [],
    filteredExecutions: [],
    currentUrl: '',
    expanded: {},
  };

  constructor() {
    super();

    this.refreshDataSet = this.refreshDataSet.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.getNextData = this.getNextData.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.selectMultiple = this.selectMultiple.bind(this);
    this.selectOne = this.selectOne.bind(this);
    this.exportToCSV = this.exportToCSV.bind(this);
    this.publishReport = this.publishReport.bind(this);
    this.publishToJIRA = this.publishToJIRA.bind(this);
    this.onSearchInputChange = this.onSearchInputChange.bind(this);
    this.onAbortTestCasesClick = this.onAbortTestCasesClick.bind(this);
    this.onDeleteTestCasesClick = this.onDeleteTestCasesClick.bind(this);
    this.onCommonFieldChange = this.onCommonFieldChange.bind(this);
    this.onChannelClick = this.onChannelClick.bind(this);
    this.onResizedChange = this.onResizedChange.bind(this);
    this.reSubmit = this.reSubmit.bind(this);
    this.onRefreshSingleExecution = this.onRefreshSingleExecution.bind(this);
    this.openPlaywrightReport = this.openPlaywrightReport.bind(this);
    this.showPlaywrightReport = this.showPlaywrightReport.bind(this);
  }

  componentDidMount() {
    const source = this.props.services[2]
      ? this.props.services[2].id
      : this.props.services[0].id;

    this.setState(
      {
        source, // first hosting service
      },
      () => {
        if (this.props.origin) {
          this.setState(
            {
              source: this.props.origin,
            },
            this.fetchData
          );
        } else {
          this.fetchData();
        }
      }
    );
  }

  fetchData() {
    const { state } = this;
    const LIMIT = 15;
    const STATUS = 'all';
    const dataSources = {
      cjt: this.normalizeFn(debouncedGetCustomTestCases, STATUS, LIMIT),
      testdata: this.normalizeFn(debouncedGetCustomOrders, STATUS, LIMIT),
      default: this.normalizeFn(
        debouncedGetHostingExecutions,
        state.source,
        LIMIT,
        0,
        STATUS
      ),
      testcasesshared: this.normalizeFn(
        debouncedGetCustomSharedExecutions,
        state.source,
        LIMIT,
        0,
        STATUS
      ),
    };
    const dataSource =
      this.state.activetab === 'my-reports'
        ? dataSources[state.source] || dataSources.default
        : dataSources.testcasesshared;

    this.setState({
      loadingRecords: true,
      records: [],
      filteredExecutions: [],
    });

    dataSource()
      .then(({ data, config }) => {
        const records = data.results.map((result) => {
          result.checked = false;

          return result;
        });
        this.setState({
          records,
          nextUrl: data.pagination.nextUrl,
          refreshing: false,
          loadingRecords: false,
          testCasesColumns: this.getTestCasesColumns(),
          filteredExecutions: this.filterRecords(records),
          currentUrl: config.baseURL + config.url,
        });
      })
      .catch(() => {
        this.setState({
          loadingRecords: false,
        });
      });
  }

  getNextData(dir) {
    const getNextUrl = (dir) => {
      switch (dir) {
        case 'next':
          return this.state.nextUrl;
        case 'prev':
          return this.state.prevUrl;
        default:
          return this.state.currentUrl; // refresh
      }
    };
    const url = getNextUrl(dir);

    this.setState({
      loadingRecords: true,
      records: [],
      filteredExecutions: [],
      // clear expanded execution records to do a clean refresh
      expandedExecutions: new Set(),
      // keep expanded records only when refreshing
      expanded: dir === 'current' ? this.state.expanded : {},
    });

    fetchNextDataSet(url).then((response) => {
      this.setState({
        loadingRecords: false,
        nextUrl: response.data.pagination.nextUrl,
        prevUrl: response.data.pagination.prevUrl,
        currentUrl: url, // store current url to be able to refresh
        records: response.data.results,
        filteredExecutions: this.filterRecords(response.data.results),
        refreshing: false,
      });
    });
  }

  getExecutionDetails(execution) {
    const service = ['cjt', 'testdata'].includes(this.state.source)
      ? this.state.source
      : 'hosting';

    return jobsQueueActions
      .getExecutionDetails(service, execution.executionid)
      .then((response) => {
        this.setState((state) => {
          const executionIndex = state.records.findIndex(
            (record) => record.executionid === response.data.executionid
          );

          if (execution) {
            const records = state.records;

            records[executionIndex] = {
              ...response.data,
              transactions: response.data.items,
            };
            const filteredExecutions = this.filterRecords(records);

            delete records[executionIndex].items;

            return { records, filteredExecutions };
          }

          return {};
        });
      });
  }

  getSingleExecutionDetails(execution) {
    if (!this.state.expandedExecutions.has(execution.executionid)) {
      this.setState(
        (state) => {
          const { expandedExecutions } = state;

          expandedExecutions.add(execution.executionid);

          return { expandedExecutions };
        },
        () => {
          if (!execution.transactions) {
            const service = ['cjt', 'testdata'].includes(this.state.source)
              ? this.state.source
              : 'hosting';

            jobsQueueActions
              .getExecutionDetails(service, execution.executionid)
              .then((response) => {
                this.setState((state) => {
                  const executionIndex = state.records.findIndex(
                    (record) => record.executionid === response.data.executionid
                  );

                  if (execution) {
                    const records = state.records;

                    records[executionIndex] = {
                      ...response.data,
                      transactions: response.data.items
                        .map((item) => {
                          if (item.status === 'failed') {
                            const errorCategory =
                              item?.errorCategory || 'notavailable';
                            return {
                              ...item,
                              errorCategory,
                            };
                          }
                          return item;
                        })
                        .map((item) => {
                          if (item.status === 'failed') {
                            const errorCategories = [
                              {
                                abreviation: 'IE',
                                fullText: 'Infrastructure Error',
                                icon: 'bi bi-server',
                                keyword: 'infrastructure',
                              },
                              {
                                abreviation: 'PE',
                                fullText: 'Product Error',
                                icon: 'bi bi-box',
                                keyword: 'product',
                              },
                              {
                                abreviation: 'SAE',
                                fullText: 'Script/Application Error',
                                icon: 'bi bi-file-earmark-code',
                                keyword: 'script',
                              },
                              {
                                abreviation: 'NA',
                                fullText: 'Not Available',
                                icon: '',
                                keyword: 'notavailable',
                              },
                            ];
                            const errorCategory = errorCategories.find(
                              ({ keyword }) =>
                                item.errorCategory
                                  .toLowerCase()
                                  .includes(keyword)
                            );
                            return {
                              ...item,
                              errorCategory,
                            };
                          }
                          return item;
                        }),
                    };

                    delete records[executionIndex].items;
                    // update filteredExecutions when transactions are fetched
                    const filteredExecutions = this.filterRecords(records);
                    return { records, filteredExecutions };
                  }

                  return {};
                });
              });
          }
        }
      );
    }
  }

  handleChange({ target }) {
    const { name, value } = target;
    let sourceValue = value;
    let newState;

    if (name === 'source' && value === 'testcases') {
      sourceValue = 'cjt';
    }

    newState = { [name]: sourceValue };
    newState.expandedExecutions = new Set();

    if (name === 'limit' && sourceValue < 1) {
      return false;
    }

    if (name === 'source' || name === 'activetab') {
      newState.records = [];
      newState.selectedEntries = {};
      newState.selectedRecords = new Set();
      newState.selectedTransactions = new Set();
      newState.loadingRecords = true;
    }

    if (name === 'source') {
      newState.status = 'all';

      this.props.setDefaultFilter(sourceValue);
    }

    this.setState(newState, this.fetchData);
  }

  handleFilterChange({ target }) {
    const filteredExecutions = this.filterRecords(this.state.records, {
      [target.name]: target.value,
    });
    this.setState((state) => {
      return {
        filters: { ...state.filters, [target.name]: target.value },
        filteredExecutions,
      };
    });
  }

  filterRecords(records, filterModel = this.state.filters) {
    const filterFunction = (record, filterKey, value) => {
      let key = filterKey;

      if (filterKey === 'status' && !record[key]) {
        key = 'overall_status';
      }

      if (value === 'completed' && record[key] === 'success') {
        return true;
      }

      return record[key] === value;
    };

    const filterEntries = Object.entries(filterModel);
    const applyFilters = (records) => {
      let filteredRecords = [];
      filterEntries.forEach(([filterKey, value]) => {
        if (filterKey === 'status' && value === 'all') {
          filteredRecords = records;

          return;
        }

        filteredRecords = [
          ...filteredRecords,
          ...records.filter((record) => {
            return filterFunction(record, filterKey, value);
          }),
        ];
      });
      return filteredRecords;
    };
    const filteredExecutions = applyFilters(records);

    // once executions are filtered:
    // then filter transactions applying same criteria
    return filteredExecutions.map((execution) => {
      if (execution.transactions) {
        return {
          ...execution,
          transactions: applyFilters(execution.transactions).map(
            (transaction) => {
              return {
                ...transaction,
                executionid: execution.executionid,
                service: this.state.source,
              };
            }
          ),
        };
      }
      return execution;
    });
  }

  refreshDataSet() {
    this.setState({ refreshing: true });

    this.getNextData('current');
  }

  openPlaywrightReport() {
    const record = this.state.records.filter((r) =>
      this.state.selectedRecords.has(r.executionid)
    )[0];
    const url = record.executionReportUrl;
    if (url) {
      window.open('/playwright/report/' + record['executionid'], '_blank');
    }
  }

  showPlaywrightReport() {
    const record = this.state.records.filter((r) =>
      this.state.selectedRecords.has(r.executionid)
    )[0];
    return !(
      this.state.selectedRecords &&
      this.state.selectedRecords.size === 1 &&
      record &&
      record.tools &&
      record.tools === 'playwright' &&
      (['failed', 'success'].indexOf(record.overall_status) !== -1 ||
        ['failed', 'success'].indexOf(record.status) !== -1) &&
      'executionReportUrl' in record
    );
  }

  onRefreshSingleExecution(executionid) {
    return () => {
      const execution = this.state.records.find(
        (record) => record.executionid === executionid
      );

      if (execution) {
        this.props.setLightboxState({ visible: true });

        this.getExecutionDetails(execution)
          .then(() => {
            this.props.setLightboxState({ visible: false });
          })
          .catch(() => {
            this.props.setLightboxState({ visible: false });
          });
      }
    };
  }

  onSearchInputChange({ target }) {
    const { records } = this.state;
    const value = target.value.toLowerCase();

    const executionsFilteredBySearch = this.filterRecords(records).filter(
      (execution) => {
        if (
          execution.executionname &&
          execution.executionname.toLowerCase().includes(value)
        )
          return true;
        if (
          execution.executionid &&
          execution.executionid.toLowerCase().includes(value)
        )
          return true;
        if (
          execution.overall_status &&
          execution.overall_status.toLowerCase().includes(value)
        )
          return true;
        if (
          execution.createddate &&
          execution.createddate.toLowerCase().includes(value)
        )
          return true;
        if (execution.env && execution.env.toLowerCase().includes(value))
          return true;
        if (
          this.state.activetab === 'shared-reports' &&
          execution.sharedby.toLowerCase().includes(value)
        )
          return true;

        return false;
      }
    );
    this.setState({
      searchValue: value,
      filteredExecutions: executionsFilteredBySearch,
    });
  }

  onCommonFieldChange({ target }) {
    this.setState({ [target.name]: target.value });
  }

  onAbortTestCasesClick() {
    const { selectedRecords } = this.state;
    const selectedTransactions = new Set(this.state.selectedTransactions);
    const toBeAborted = {};
    const requests = [];

    this.setState({ aborting: true });

    selectedRecords.forEach((executionid) => {
      const execution = this.state.records.find(
        (r) => r.executionid === executionid
      );
      const transactions = execution.transactions.map((t) => t.transactionid);

      toBeAborted[executionid] = transactions;

      transactions.forEach((transactionid) => {
        if (selectedTransactions.has(transactionid)) {
          selectedTransactions.delete(transactionid);
        }
      });
    });

    selectedTransactions.forEach((transactionid) => {
      const execution = this.state.records.find((record) => {
        const selectedTransaction = record.transactions.find(
          (t) => t.transactionid === transactionid
        );

        return !!selectedTransaction;
      });

      if (!toBeAborted[execution.executionid]) {
        toBeAborted[execution.executionid] = [];
      }

      toBeAborted[execution.executionid].push(transactionid);
    });

    Object.entries(toBeAborted).forEach(([executionid, transactionids]) => {
      requests.push(
        postHostingAbortTransactions(executionid, {
          reason: this.state.abortReason,
          transactionids,
        })
      );
    });

    Promise.all(requests).finally(() => {
      this.setState({ aborting: false });
      this.refreshDataSet();
      this.toggleModal();
    });
  }

  onDeleteTestCasesClick() {
    this.setState({ requestInProgress: true });

    deleteTransactions(this.state.source, this.state.selectedEntries).then(
      () => {
        this.setState({ requestInProgress: false, selectedEntries: {} });

        this.toggleModal();
        this.refreshDataSet();
      }
    );
  }

  onChannelClick(channel) {
    this.setState({ activetab: channel }, () => {
      this.handleChange({
        target: {
          name: 'activetab',
          value: channel,
        },
      });
    });
  }

  onResizedChange(resizedColumns) {
    this.setState({ resizedColumns });
  }

  toggleModal(options = {}) {
    const modal = {
      ...this.state.modal,
      ...options,
      visible: !!options.id,
    };

    this.setState({ modal });
  }

  selectMultiple(executionid) {
    const { selectedRecords, selectedTransactions, selectedEntries } =
      this.state;
    const execution = this.state.records.find(
      (r) => r.executionid === executionid
    );

    if (execution.transactions) {
      // new implementation
      const transactions = execution.transactions.map((t) => t.transactionid);
      if (!selectedEntries[executionid]) {
        selectedEntries[executionid] = new Set(transactions);
      } else {
        // unselect all
        if (selectedEntries[executionid].size === transactions.length) {
          delete selectedEntries[executionid];
        } else {
          // select all
          selectedEntries[executionid] = new Set(transactions);
        }
      }

      // old implementation
      if (selectedRecords.has(executionid)) {
        execution.transactions
          .map((t) => t.transactionid)
          .forEach(selectedTransactions.delete, selectedTransactions);

        selectedRecords.delete(executionid);
      } else {
        execution.transactions
          .map((t) => t.transactionid)
          .forEach(selectedTransactions.add, selectedTransactions);

        selectedRecords.add(executionid);
      }
    } else {
      // new implementation
      if (!selectedEntries[executionid]) {
        selectedEntries[executionid] = new Set();
      } else {
        delete selectedEntries[executionid];
      }

      // old implementation
      if (selectedRecords.has(executionid)) {
        selectedRecords.delete(executionid);
      } else {
        selectedRecords.add(executionid);
      }
    }
    console.log('selectedRecords', selectedRecords);
    console.log('Records', this.state.records);
    console.log(
      'first record',
      this.state.records.filter((r) => selectedRecords.has(r.executionid))[0]
    );

    this.setState({ selectedRecords, selectedTransactions, selectedEntries });
  }

  selectOne(transactionid, executionid) {
    const { selectedRecords, selectedTransactions, selectedEntries } =
      this.state;
    const execution = this.state.records.find(
      (r) => r.executionid === executionid
    );
    const transactions = execution.transactions;
    let executionSelected = true;

    // new implementation
    if (!selectedEntries[executionid]) {
      selectedEntries[executionid] = new Set();

      selectedEntries[executionid].add(transactionid);
    } else {
      if (selectedEntries[executionid].has(transactionid)) {
        selectedEntries[executionid].delete(transactionid);

        if (selectedEntries[executionid].size === 0) {
          delete selectedEntries[executionid];
        }
      } else {
        selectedEntries[executionid].add(transactionid);
      }
    }

    // old implementation uses two different sets to keep track of
    // selected entries, but they are not related. Switching to a
    // different approach using a single object
    if (selectedTransactions.has(transactionid)) {
      if (selectedRecords.has(executionid)) {
        selectedRecords.delete(executionid);
      }

      selectedTransactions.delete(transactionid);
    } else {
      selectedTransactions.add(transactionid);

      transactions.forEach((t) => {
        if (!selectedTransactions.has(t.transactionid)) {
          executionSelected = false;
        }
      });

      if (executionSelected) {
        selectedRecords.add(executionid);
      }
    }

    this.setState({ selectedRecords, selectedTransactions, selectedEntries });
  }

  exportToCSV() {
    const transactionids = Object.values(this.state.selectedEntries).reduce(
      (acc, c) => [...acc, ...c],
      []
    );
    const transactions = [];

    this.state.records.forEach((execution) => {
      if (execution.transactions) {
        execution.transactions.forEach((transaction) => {
          if (transactions.length < transactionids.length) {
            if (transactionids.includes(transaction.transactionid)) {
              transactions.push({
                executionid: transaction.executionid,
                ...transaction,
              });
            }
          }
        });
      }
    });

    if (transactions.length) {
      const report = CSVReportGenerator(transactions, this.state.source);
      const blob = new Blob([report], { type: 'text/csv' });
      const csv = URL.createObjectURL(blob);

      downloadFile.fromContent('report.csv', csv);
    }
  }

  parseStringToObject(string) {
    const object = {};

    if (typeof string === 'object') {
      Object.entries(string).forEach(([key, value]) => {
        let parsedValue = '';

        try {
          parsedValue = this.parseStringToObject(value);
        } catch (e) {
          parsedValue = value;
        }

        object[key] = parsedValue;
      });

      return object;
    }

    return JSON.parse(string);
  }

  reSubmit(record, executionid, isNewReport, userinput) {
    const { state } = this;

    if (record.length > 0) {
      let payload;
      payload = record.map((r) => ({
        transactionids: r.transactionid,
        userinput: this.parseStringToObject(userinput[r.transactionid] || {}),
      }));

      this.toggleModal();

      if (record.length > 1) {
        this.props.addNewNotification(
          'Job resubmit - Started (Jobs in state Resubmit False/In-Progress/Queueed would be skiipped)',
          'success'
        );
      } else {
        this.props.addNewNotification('Job resubmit - Started ', 'success');
      }

      return postResubmit(state.source, executionid, payload, isNewReport)
        .then((response) => {
          const detail = `${response.data.message} : ${JSON.stringify(
            response.data.queued
          )}`;

          this.refreshDataSet();
          this.props.addNewNotification(detail, 'success');

          return response;
        })
        .catch(({ response }) => {
          if (response) {
            console.log(response);
            const { detail } = response.data;
            this.props.addNewNotification(detail, 'danger');
          }
        });
    }
  }

  publishReport(data) {
    const { state } = this;
    const records = state.records.filter((r) =>
      state.selectedRecords.has(r.executionid)
    );
    const payload = {
      executionids: records.map((record) => {
        let createdby;

        if (state.activetab === 'my-reports') {
          if (state.source === 'cjt' || state.source === 'testdata') {
            createdby = this.props.userInfo.emailid;
          } else {
            createdby = record.executedby;
          }
        } else {
          createdby = record.sharedby;
        }

        return {
          executionid: record.executionid,
          createdby,
        };
      }),
      emailrecipients: data.id.emailrecipients
        ? data.id.emailrecipients.split(',').map((e) => e.trim())
        : '',
      comments: data.id.comments || '',
      publishreport: true,
    };

    return postPublishReports(state.source, payload).then((response) => {
      this.toggleModal({
        id: 'jobs-queue-report-sent',
        title: 'Report sent',
        message: response.data.message,
      });

      return response;
    });
  }

  publishToJIRA(data) {
    const { state } = this;
    const executionid = Object.keys(state.selectedEntries)[0];
    const transactions = state.selectedEntries[executionid];
    const payload = {
      jiraTestCycleId: data.id.jiraTestCycleID,
      executionid,
      transactionid: Array.from(transactions),
    };

    return postPublishToJIRA(state.source, payload).then((response) => {
      this.toggleModal({
        id: 'jobs-queue-report-sent',
        title: 'Job published to JIRA',
        message: response.data.message,
      });

      return response;
    });
  }

  normalizeFn(fn, ...args) {
    return () => fn(...args);
  }

  formatTimestamp(timestamp) {
    const date = new Date(timestamp);

    return date.toLocaleString();
  }

  renderModal() {
    const { state } = this;
    let modalProps = {};

    if (state.modal.visible) {
      switch (state.modal.id) {
        case 'jobs-queue-details':
          let parent = state.records;
          let record;

          if (state.modal.parentid) {
            parent = parent.find(
              (r) => r.executionid === state.modal.parentid
            ).transactions;
          }

          record = parent.find((r) => r.transactionid === state.modal.recordid);
          record.executionid = state.modal.parentid;

          return (
            <JobsQueueModal
              open={state.modal.visible}
              onClose={this.toggleModal}
              service={state.source}
              record={record}
            />
          );
        case 'jobs-queue-confirmation':
          modalProps = {
            message:
              'You are about to abort the selected test cases. Do yo want to proceed?',
            onClose: this.toggleModal,
            onConfirm: this.onAbortTestCasesClick,
            loading: this.state.aborting,
          };

          return (
            <ConfirmationModal {...modalProps}>
              <div className="modal-form modal-content-wrapper row">
                <div className="col-sm-6 form-field" key="abortReason">
                  <label htmlFor="abortReason">Reason</label>
                  <input
                    name="abortReason"
                    type="text"
                    onChange={this.onCommonFieldChange}
                  />
                </div>
              </div>
            </ConfirmationModal>
          );
        case 'jobs-queue-report':
          return (
            <MetafieldsModal
              environment=""
              title="Publish report"
              onClose={this.toggleModal}
              metafields={[
                {
                  field: 'emailrecipients',
                  name: 'Email recipients',
                  type: 'string',
                  small: `Enter recipient's emailid separated by comma`,
                },
                {
                  field: 'comments',
                  name: 'Comments',
                  type: 'text',
                  default:
                    'You can add known defects or any comments to be included in the email report',
                  mandatory: 'false',
                },
              ]}
              elements={[{ id: 'id' }]}
              onSubmit={this.publishReport}
            />
          );
        case 'jobs-queue-publish-to-jira':
          return (
            <MetafieldsModal
              environment=""
              title="Publish to JIRA"
              onClose={this.toggleModal}
              metafields={[
                {
                  field: 'jiraTestCycleID',
                  name: 'JIRA Test Cycle ID',
                  type: 'string',
                  small: `Enter JIRA Test Cycle ID`,
                },
              ]}
              elements={[{ id: 'id' }]}
              onSubmit={this.publishToJIRA}
            />
          );
        case 'jobs-queue-report-sent':
          return (
            <ConfirmationModal
              title={this.state.modal.title}
              message={this.state.modal.message}
              onClose={this.toggleModal}
              onConfirm={this.toggleModal}
              submitButtonText="OK"
            />
          );

        case 'jobs-queue-resubmit-view':
          let executionid = state.modal.parentid;
          let execution;
          let transactions;

          if (executionid) {
            // looking for execution based on parentid
            execution = state.records.find(
              (record) => record.executionid === executionid
            );
            // getting selected transaction based on recordid
            transactions = execution.transactions.filter(
              (transaction) =>
                transaction.transactionid === state.modal.recordid
            );
          } else {
            // selecting first entry because there can't be resubmits of multiple executions
            executionid = Object.keys(state.selectedEntries)[0];
            execution = state.records.find(
              (record) => record.executionid === executionid
            );
            // selecting every selected transaction
            console.log(execution);
            transactions = execution.transactions.filter((transaction) =>
              state.selectedEntries[executionid].has(transaction.transactionid)
            );
          }

          return (
            <JobsQueueResubmitModal
              open={state.modal.visible}
              service={state.source}
              record={transactions}
              executionid={executionid}
              onClose={this.toggleModal}
              onSubmit={this.reSubmit}
            />
          );
        case 'jobs-queue-delete-confirmation':
          modalProps = {
            title: 'Delete executions',
            message:
              'You are about to delete the selected test executions. Do yo want to proceed? This action cannot be undone.',
            onClose: this.toggleModal,
            onConfirm: this.onDeleteTestCasesClick,
            loading: this.state.requestInProgress,
            submitButtonText: 'Delete',
          };

          return <ConfirmationModal {...modalProps} />;
        default:
          return null;
      }
    }

    return null;
  }

  renderTableControls() {
    const status = {
      cjt: [
        'success',
        'failed',
        'in-progress',
        'submitted',
        'queued',
        'resubmitted',
      ],
      testdata: [
        'completed',
        'failed',
        'in-progress',
        'submitted',
        'queued',
        'resubmitted',
      ],
      default: ['success', 'failed', 'in-progress', 'submitted', 'queued'],
    };
    const availableStatus = (status[this.state.source] || status.default).map(
      (s) => (
        <option key={s} value={s}>{`${s.charAt(0).toUpperCase()}${s
          .slice(1)
          .replace('-', ' ')}`}</option>
      )
    );
    const services = this.props.services.map((service) => (
      <option key={service.id} value={service.id}>
        {service.displayName}
      </option>
    ));
    const selectedInProgressTransactions = new Set();
    const selectedFinishedTransactions = new Set();
    const selectedExecutionsResubmit = [];
    const selectedExecutionsResubmitStatus = [];
    let { source } = this.state;
    let exportEnabled = false;
    let isPrefillOrder = false;
    let resubmitEnabled = false;
    let isDeleteEnabled = false;

    // move cjt and testdata to the end of options
    services.push(services.splice(1, 1)); // Test data
    services.push(services.splice(0, 1)); // CJT

    // export rules
    if (
      this.state.source === 'testdata' &&
      this.state.selectedRecords.size === 1
    ) {
      exportEnabled = true;
    } else if (
      this.state.source !== 'testdata' &&
      this.state.selectedTransactions.size > 0
    ) {
      exportEnabled = true;
    }

    // transaction processing
    // NEEDS REFACTOR, it is currently looping through all records even if they are not selected
    this.state.records.forEach((record) => {
      if (record.transactions) {
        record.transactions.forEach((transaction) => {
          // transaction grouping
          if (this.state.selectedTransactions.has(transaction.transactionid)) {
            if (['in-progress', 'queued'].includes(transaction.status)) {
              selectedInProgressTransactions.add(transaction.transactionid);
            } else {
              selectedFinishedTransactions.add(transaction.transactionid);
            }
          }

          if (this.state.selectedTransactions.has(transaction.transactionid)) {
            selectedExecutionsResubmit.push(transaction.executionid);
            selectedExecutionsResubmitStatus.push(
              transaction.resubmit ? true : false
            );

            if (transaction.fulfillment === 'pre-fill') {
              isPrefillOrder = true;
            }
          }
        });
      }
    });

    // if all selected executionids are the same, enable resubmit else disable it
    if (selectedExecutionsResubmit.length > 1) {
      if (this.areSame(selectedExecutionsResubmit)) {
        resubmitEnabled = true;
      }
      //if only 1 execution id enable resubmit above condition not taking care
    } else if (selectedExecutionsResubmit.length === 1) {
      resubmitEnabled = true;
    }

    //check for resubmit flag if false for all resubmitenable set to false
    if (
      this.areSame(selectedExecutionsResubmitStatus) &&
      selectedExecutionsResubmitStatus[0] === false
    ) {
      resubmitEnabled = false;
    }

    // if execution group has all tx in status (in progress , queued) disable resubmit
    if (
      selectedInProgressTransactions.size ===
      this.state.selectedTransactions.size
    ) {
      resubmitEnabled = false;
    }

    if (isPrefillOrder) {
      resubmitEnabled = false;
    }

    // if more than one transaction that is not in progress is selected
    if (
      selectedInProgressTransactions.size === 0 &&
      selectedFinishedTransactions.size > 0
    ) {
      isDeleteEnabled = true;
    }

    if (source === 'cjt') {
      source = 'testcases';
    }

    return (
      <section className="table-filters">
        <div className="form-group">
          <label htmlFor="status">Status</label>
          <select
            className="form-control"
            disabled={this.state.loadingRecords}
            name="status"
            value={this.state.filters.status}
            onChange={this.handleFilterChange}
            id="status"
          >
            <option value="all">All</option>
            {availableStatus}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="status">Services</label>
          <select
            className="form-control"
            disabled={this.state.loadingRecords}
            name="source"
            value={source}
            onChange={this.handleChange}
            id="service"
          >
            {services}
          </select>
        </div>

        <div className="table-refresh">
          <button
            type="button"
            className="btn btn-light"
            disabled={this.state.loadingRecords}
            onClick={this.refreshDataSet}
          >
            {this.state.refreshing ? 'Refreshing...' : 'Refresh'}
          </button>
        </div>

        <div className="dropdown">
          <button
            className="btn btn-light dropdown-toggle"
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Actions
          </button>

          <div className="dropdown-menu">
            <button
              className="dropdown-item"
              type="button"
              onClick={this.exportToCSV}
              disabled={!exportEnabled}
            >
              Export to CSV
            </button>

            {!['Perf testing'].includes(this.props.origin) && (
              <React.Fragment>
                <button
                  className="dropdown-item"
                  type="button"
                  onClick={() => this.toggleModal({ id: 'jobs-queue-report' })}
                  disabled={!(this.state.selectedRecords.size > 0)}
                >
                  Publish report
                </button>
              </React.Fragment>
            )}
            {
              <React.Fragment>
                <button
                  className="dropdown-item"
                  type="button"
                  onClick={() => this.openPlaywrightReport()}
                  disabled={this.showPlaywrightReport()}
                >
                  Playwright Report
                </button>
              </React.Fragment>
            }

            {!['testdata', 'cjt', 'Perf testing'].includes(
              this.state.source
            ) && (
              <React.Fragment>
                <button
                  className="dropdown-item"
                  type="button"
                  onClick={() =>
                    this.toggleModal({ id: 'jobs-queue-publish-to-jira' })
                  }
                  disabled={
                    !(Object.keys(this.state.selectedEntries).length === 1)
                  }
                >
                  Publish to JIRA
                </button>
              </React.Fragment>
            )}

            {!['testdata', 'cjt', 'Perf testing'].includes(this.state.source) &&
              this.state.activetab === 'my-reports' && (
                <React.Fragment>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={() =>
                      this.toggleModal({ id: 'jobs-queue-confirmation' })
                    }
                    disabled={!selectedInProgressTransactions.size > 0}
                  >
                    Abort
                  </button>
                </React.Fragment>
              )}

            {!['Perf testing'].includes(this.props.origin) && (
              <button
                className="dropdown-item"
                type="button"
                onClick={() =>
                  this.toggleModal({
                    recordid: null,
                    parentid: null,
                    id: 'jobs-queue-resubmit-view',
                  })
                }
                disabled={!resubmitEnabled}
              >
                Resubmit
              </button>
            )}

            {!['testdata', 'cjt', 'Perf testing'].includes(this.props.origin) &&
              this.state.activetab === 'my-reports' && (
                <button
                  className="dropdown-item"
                  type="button"
                  onClick={() =>
                    this.toggleModal({ id: 'jobs-queue-delete-confirmation' })
                  }
                  disabled={!isDeleteEnabled}
                >
                  Delete
                </button>
              )}
          </div>
        </div>
      </section>
    );
  }

  renderExecutionsTable() {
    const { filteredExecutions } = this.state;
    const selectMultipleProps = (record) => ({
      checked: this.state.selectedRecords.has(record.executionid),
      onChange: () => this.selectMultiple(record.executionid),
    });

    const schemacolumnexecutions =
      this.state.activetab === 'my-reports'
        ? schema.executions
        : schema.sharedreportExecutions;

    const columns = [
      {
        Cell: ({ original }) => (
          <span className="checkbox-wrapper">
            <Checkbox {...selectMultipleProps(original)} />
          </span>
        ),
        width: 40,
        resizable: false,
      },
      {
        Cell: ({ original }) => (
          <button
            className="btn btn-link btn-refresh"
            onClick={this.onRefreshSingleExecution(original.executionid)}
          >
            <i className="bi bi-arrow-clockwise"></i>
          </button>
        ),
        width: 48,
        resizable: false,
      },

      ...schemacolumnexecutions,

      {
        Header: 'Results',
        width: this.state.activetab === 'my-reports' ? '100%' : 130,
        Cell: ({ original }) => (
          <section className="executions-table-count">
            <span title="Queued" className="count status-queued">
              {original.queued_count}
            </span>
            <span title="In progress" className="count status-in-progress">
              {original.inprogress_count}
            </span>
            <span title="Failed" className="count status-failed">
              {original.failed_count}
            </span>
            <span title="Success" className="count status-success">
              {original.success_count}
            </span>
          </section>
        ),
      },
    ];

    let tablePlaceholder = 'No records to show';

    if (this.state.loadingRecords) {
      tablePlaceholder = <LoadingSpinner text="Loading records" />;
    }

    return (
      <ReactTable
        data={filteredExecutions}
        columns={columns}
        showPagination={false}
        pageSize={filteredExecutions.length}
        className="-highlight"
        onResizedChange={this.onResizedChange}
        SubComponent={(row) =>
          this.renderTestCasesTable(row.original.transactions, row.original)
        }
        collapseOnDataChange={false}
        resizable={false}
        sortable={false}
        NoDataComponent={() => (
          <div className="table-loading-placeholder">{tablePlaceholder}</div>
        )}
        expanded={this.state.expanded}
        onExpandedChange={(expanded) => this.setState({ expanded })}
      />
    );
  }

  getTestCasesColumns() {
    return [
      {
        Cell: '',
        width: 34,
      },
      {
        Cell: ({ original: { transactionid, executionid } }) => (
          <span className="checkbox-wrapper">
            <Checkbox
              checked={this.state.selectedTransactions.has(transactionid)}
              onChange={() => this.selectOne(transactionid, executionid)}
            />
          </span>
        ),
        width: 40,
      },
      {
        Cell: '',
        width: 48,
      },

      ...(this.state.activetab === 'my-reports'
        ? schema.testCases
        : schema.sharedreportTestcases),

      {
        Cell: ({ original }) => {
          const commonProps = {
            type: 'button',
            className: 'button button-link no-spacing',
          };
          const viewButtonProps = {
            ...commonProps,
            onClick: () =>
              this.toggleModal({
                recordid: original.transactionid,
                parentid: original.executionid,
                id: 'jobs-queue-details',
              }),
          };
          const resubmitButtonProps = {
            ...commonProps,
            disabled:
              !original.resubmit ||
              ['in-progress', 'queued'].includes(original.status) ||
              (!original.fulfillment
                ? false
                : original.fulfillment === 'pre-fill'
                ? true
                : false),
            onClick: () =>
              this.toggleModal({
                recordid: original.transactionid,
                parentid: original.executionid,
                id: 'jobs-queue-resubmit-view',
              }),
          };

          return (
            <div className="job-actions">
              <button {...viewButtonProps}>View</button>

              <div>
                <ReactTooltip id="resubmit-button" place="top" effect="solid">
                  Resubmit will rerun the same testcase
                </ReactTooltip>
                <button
                  data-tip
                  data-for="resubmit-button"
                  {...resubmitButtonProps}
                >
                  Resubmit
                </button>
              </div>
            </div>
          );
        },
      },
    ];
  }

  renderTestCasesTable(records, parent) {
    const { testCasesColumns } = this.state;

    if (!records) {
      // fetch transactions
      this.getSingleExecutionDetails(parent);
    }

    if (this.props.services.length === 0) {
      return <LoadingSpinner text="Loading services" />;
    } else {
      this.state.resizedColumns.forEach((column) => {
        const currentColumnIndex = testCasesColumns.findIndex(
          (c) => c.id === column.id
        );

        if (testCasesColumns[currentColumnIndex]) {
          testCasesColumns[currentColumnIndex].width = column.value;
        }
      });

      return (
        <section>
          <TestCasesTable
            data={records || []}
            columns={this.state.testCasesColumns}
            isLoading={this.state.loadingRecords || !records}
          />
        </section>
      );
    }
  }

  areSame(arr) {
    // Put all array elements in a Set
    const s = new Set(arr);

    // If all elements are same, size of
    // Set should be 1, as Set contains only distinct values.
    return s.size === 1;
  }

  render() {
    const modal = this.renderModal();
    const controls = this.renderTableControls();
    const table = this.renderExecutionsTable();
    const searchProps = {
      type: 'search',
      onChange: this.onSearchInputChange,
      value: this.state.searchValue,
      name: 'search',
      className: 'form-control',
      placeholder: 'Search',
    };
    const channelsProps = {
      channels: [
        { subcategoryname: 'My Reports', subcategoryid: 'my-reports' },
        { subcategoryname: 'Shared Reports', subcategoryid: 'shared-reports' },
      ],
      onClick: this.onChannelClick,
      selectedChannel: this.state.activetab,
    };
    return (
      <div className="jobs-queue-wrapper">
        {modal}

        <section className="section-title">
          <section className="section-title">
            <h2 className="title">Jobs Queue</h2>
            <a
              href="https://wiki.autodesk.com/display/DES/Testing+Hub"
              target="_blank"
              rel="noopener noreferrer"
            >
              What is this?
            </a>
          </section>
        </section>

        <Channels {...channelsProps} />

        {controls}

        <hr />

        <section className="search-pagination d-flex justify-content-between">
          <div className="form-group col-md-3 pl-0">
            <input {...searchProps} aria-describedby="search" />
          </div>

          <div className="f-right">
            <div className="table-pagination">
              {this.state.prevUrl && (
                <button
                  className="button button-link"
                  onClick={() => this.getNextData('prev')}
                  type="button"
                >
                  Previous
                </button>
              )}

              {this.state.nextUrl && (
                <button
                  className="button button-link"
                  onClick={() => this.getNextData('next')}
                  type="button"
                >
                  Next
                </button>
              )}
            </div>
          </div>
        </section>

        {table}

        <div className="table-legend">
          <span title="Queued" className="status status-queued"></span>Queued
          <span
            title="In progress"
            className="status status-in-progress"
          ></span>
          In progress
          <span title="Failed" className="status status-failed"></span>Failed
          <span title="Success" className="status status-success"></span>Success
          <span
            title="Resubmitted"
            className="status status-resubmitted"
          ></span>
          Resubmitted
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  origin: state.jobsQueue.defaultSource,
  services: state.app.availableServices,
  userInfo: state.app.userInfo,
});

const mapDispatchToProps = (dispatch) => ({
  setDefaultFilter: (source) => dispatch(setDefaultFilter(source)),
  addNewNotification: (message, category) =>
    dispatch(addNewNotification(message, category)),
  setLightboxState: (state) => dispatch(setLightboxState(state)),
});

export default connect(mapStateToProps, mapDispatchToProps)(JobsQueue);
